@import '~/lib/vars';

.generator {
  .track-selection {
    background-color: var(--green-primary);
    min-height: calc(100vh - var(--menu-height));
    display: flex;
    flex-wrap: wrap;

    h1 {
      width: 100%;
    }
    .search-area {
      margin: 0 40px;
      width: calc(50% - 80px);
      position: relative;

      .search {
        input {
          width: 100%;
          border: none;
          font-size: 30px;
          padding: 10px;
          border-radius: 5px;
          box-sizing: border-box;
          color: #2f2b2b;
          font-weight: bold;
        }
        svg {
          position: absolute;
          right: 10px;
          top: 8px;
          width: 35px;
          height: 35px;
          cursor: pointer;
        }
      }
    }
  }
  .settings {
    background-color: var(--blue-primary);
    min-height: 500px;
    display: flex;
    flex-wrap: wrap;

    h1 {
      width: 100%;
      margin-top: 50px;
      color: #0b60b1;
    }
    button {
      margin: 40px;
      margin-left: auto;
      color: var(--green-primary);
      border-color: var(--green-primary);
      font-size: 30px;
    }
  }

  @media only screen and (max-width: $desktop) {
    .track-selection .search-area {
      height: 570px;
      margin: 100px auto;
      margin-top: 50px;

      margin: 0 40px;
      width: calc(100% - 80px);
    }
  }
}
