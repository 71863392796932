.sliders-svg {
  .st0 {
    fill: #1a5792;
  }
  .st1 {
    fill: var(--blue-primary);
  }
  .st2 {
    fill: var(--green-primary);
  }
}
