@import '~/lib/vars';

.track-card {
    margin: 0 auto;
    width: 100%;
    max-width: 560px;
    max-height: 560px;
    padding-top: min(100%, 560px);
    box-sizing: border-box;

    perspective: 1000px;
    transform-style: preserve-3d;
    position: relative;
    transition: 0.6s;

    .front, .back {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        backface-visibility: hidden;
        border-radius: 50px;
        box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
        background-size: cover;
    }
    .back {
        transform: rotateY(180deg);
        opacity: .85;

        .album-overlay {
            position: absolute;
            background-color: var(--blue-primary);
            border-radius: 50px;
            opacity: .85;
            width: 100%;
            height: 100%;
        }
    }
    &.flipped {
        transform: rotateY(180deg);
    }
    @media only screen and (max-width: $desktop) {
        width: 90%;
        padding-top: min(90%, 560px);
        margin-bottom: 50px;
    }
}