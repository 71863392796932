.records-svg {
  .st0 {
    fill: #294747;
  }
  .st1 {
    fill: #245f5f;
  }
  .st2 {
    fill: #223030;
  }
  .st3 {
    fill: #254848;
  }
  .st4 {
    fill: #ffffff;
  }
  .st5 {
    fill: #1d8efb;
  }
  .st6 {
    fill: #1668b7;
  }
  .st7 {
    fill: #389583;
  }
  .st8 {
    fill: #1a5692;
  }
  .st9 {
    fill: #06396b;
  }
}
