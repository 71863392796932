@import '~/lib/vars';

html,
body,
#app {
  height: 100%;
  padding: 0;
  margin: 0;
  color: var(--green-secondary);
  font-family: 'Ubuntu', sans-serif;
  background-color: var(--green-primary);
}
h1,
h2,
h3,
h4,
label,
p {
  margin: 0;
}
h1 {
  font-size: 80px;
  color: var(--light-green);
  text-align: center;
  margin: 30px 0;
}
h2 {
  font-size: 30px;
  text-align: center;
}
p {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1.5px;
  line-height: 1.5;
}
a {
  text-decoration: none;
}
*:focus {
  outline: none;
}
button {
  color: #ffffff;
  font: inherit;
  background-color: transparent;
  font-size: 19px;
  border-radius: 10px;
  padding: 10px;
  border: 2px solid #ffffff;
  cursor: pointer;
}
