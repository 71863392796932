$mobile: 480px;
$tablet: 768px;
$laptop: 1024px;
$desktop: 1200px;

:root {
  // Primary Palette
  --snow-white: #d3d5e3;
  --green-primary: #5cdb94;
  --green-secondary: #8de4af;
  --turquoise: #00b592;
  --light-green: #9fedbe;
  --green-white: #edf5e0;
  --blue-primary: #05396b;
  --green-blue: #389583;
  --menu-height: 100px;
}