@import '~/lib/vars';

.selected-tracks {
  width: calc(40% - 80px);
  position: relative;
  margin: 40px;

  h2 {
    color: var(--green-primary);
  }
  .selected-track {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    font-size: 20px;
    margin: 10px 20px 10px 0;
    color: #ffffff;
    background-color: var(--turquoise);

    .selected-track-info {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 90%;
      white-space: nowrap;
    }
    svg {
      height: 20px;
      cursor: pointer;
    }
  }
  .placeholder {
    background-color: transparent;
    border: 2px solid var(--turquoise);
    height: 20px;
  }

  @media only screen and (max-width: $desktop) {
    width: calc(100% - 80px);
  }
}

.playlist-settings {
  display: flex;
  flex-wrap: wrap;
  width: calc(60% - 80px);
  margin: 40px;

  .setting {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: calc(100% / 6);
    min-height: 300px;

    .slider {
      opacity: 0.7;
      transition: opacity 0.2s;

      &:hover {
        opacity: 1;
        cursor: pointer;
      }
    }
    .slider-name {
      width: 100%;
      text-align: center;
      margin-top: 10px;
    }
  }

  @media only screen and (max-width: $desktop) {
    width: calc(100% - 80px);
  }

  @media only screen and (max-width: $tablet) {
    margin: auto;
    .setting {
      width: calc(100% / 3);
      margin-top: 40px;
    }
  }
}
